/**
 * ----------------------------------
 * Fonts
 * ----------------------------------
 */

/* stylelint-disable */
$font-sans-serif: arial, helvetica, sans-serif;
$font-serif: times, baskerville, georgia, serif;
$font-roboto: roboto, $font-sans-serif;
$font-montserrat: montserrat, $font-sans-serif;
/* stylelint-enable */
