/**
 * ----------------------------------
 * Color
 * ----------------------------------
 */
$theme-colors: (
  "white": #ffffff,
  "grey": #b2bdc9,
  "light-grey": #f8fbff,
  "black": #000000,
  "blue": #00244c,
  "gold": #b88210,
  "light-gold": #f9f2e8,
) !default;
