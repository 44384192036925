/**
 * ----------------------------------
 * Mixins
 * ----------------------------------
 */
// CTA
%button {
  display: inline-block;
  transition: all .3s ease;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  text-align: center;
  font-size: font(small);
  font-weight: 600;
  padding: 10px calc(#{$gutter} * 2);
}

@mixin button ($color, $hover, $bg-hover) {
  @extend %button;

  color: $color;
  background-color: transparent;
  border: 2px solid $color;

  &:hover {
    color: $hover;
    background-color: $bg-hover;
    border-color: $hover;
  }
}

// Link
@mixin link($color: white, $border-color: white, $font-size: 14px) {
  color: col($color);
  text-decoration: none;
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: $font-size;
  line-height: 21px;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: col($border-color);
    transform: scaleX(1);
    transform-origin: center;
    transition: transform .3s ease;
  }

  &:hover {
    &:after {
      transform: scaleX(0);
    }
  }
}
