/**
 * ----------------------------------
 * Svg
 * ----------------------------------
 */
@svg-load cookies url('cookies.svg') {
  path,
  circle {
    fill: col(gold);
  }
}
