/**
 * Orejime stylesheet
 */
/**
 * ----------------------------------
 * Color
 * ----------------------------------
 */
/**
 * ----------------------------------
 * Fonts
 * ----------------------------------
 */
/* stylelint-disable */
/* stylelint-enable */
/**
 * ----------------------------------
 * Variables
 * ----------------------------------
 */
/**
 * ----------------------------------
 * Functions
 * ----------------------------------
 */
/**
 * ----------------------------------
 * Mixins
 * ----------------------------------
 */
button:matches(.orejime-Modal-saveButton), button:matches(.orejime-Notice-learnMoreButton, .orejime-Notice-declineButton, .orejime-Notice-saveButton) {
  display: inline-block;
  transition: all 0.3s ease;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  padding: 10px calc(15px * 2);
}

/**
 * -------------------------------------------------------------
 * Custom media
 * -------------------------------------------------------------
 */
@custom-media --xsmall (min-width: 360px);
@custom-media --small (min-width: 640px);
@custom-media --tablet (min-width: 768px);
@custom-media --medium (min-width: 960px);
@custom-media --large (min-width: 1200px);
@custom-media --xlarge (min-width: 1440px);
@custom-media --landscape (orientation: landscape);
@custom-media --portrait (orientation: portrait);
/**
 * ----------------------------------
 * Svg
 * ----------------------------------
 */
@svg-load cookies url("cookies.svg") {
  path,
circle {
    fill: #b88210;
  }
}
/**
 * ----------------------------------
 * layers
 * ----------------------------------
 */
body.orejime-layer-show {
  overflow: hidden;
}
body.orejimeBody-WithModalOpen .orejime-Notice {
  opacity: 0;
  visibility: hidden;
}
body.orejime-layer-show .orejime-Notice-parameterButton, body.orejimeBody-WithModalOpen .orejime-Notice-parameterButton {
  transform: translate3d(0, 50px, 0);
}

.orejime-AppContainer {
  transition: all 0.3s linear;
}
.orejime-AppContainer .orejime-Notice {
  background: rgba(0, 36, 76, 0.85);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  padding: calc(15px * 2) 15px;
}
.orejime-AppContainer .orejime-Notice-body {
  padding: calc(15px * 2);
  background-color: #ffffff;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  z-index: calc(10000 + 1);
  transform: translate3d(-50%, -50%, 0);
}
@media (--tablet) {
  .orejime-AppContainer .orejime-Notice-body {
    max-width: 640px;
  }
}
.orejime-AppContainer .orejime-Notice-description {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: #00244c;
  text-align: center;
  margin-bottom: calc(15px * 2);
}
.orejime-AppContainer .orejime-Notice-description:before {
  content: "";
  display: block;
  width: 70px;
  height: 70px;
  margin: 0 auto 15px;
  background-image: svg-inline(cookies);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70px;
}
.orejime-AppContainer .orejime-Notice-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 0;
}
.orejime-AppContainer .orejime-Notice-actions > li {
  list-style: none;
  flex-shrink: 0;
  flex-grow: 0;
  width: 100%;
  padding: 0 5px;
}
@media (--small) {
  .orejime-AppContainer .orejime-Notice-actions > li {
    width: 190px;
  }
}
.orejime-AppContainer .orejime-Notice-actions > li button {
  width: 100%;
}

.orejime-paramContainer {
  position: fixed;
  bottom: 0;
  right: 100px;
  z-index: 10000;
}
.orejime-paramContainer .orejime-Notice-parameterButton {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-size: 12px;
  padding: 10px;
  color: #00244c;
  background: #ffffff;
  border: none;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.25);
  transform: translate3d(0, 0, 0);
  transition: all 0.3s linear;
}

/**
 * ----------------------------------
 * Modal
 * ----------------------------------
 */
.orejime-ModalPortal .orejime-ModalOverlay {
  background: rgba(0, 36, 76, 0.85);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  padding: calc(15px * 2) 15px;
}
.orejime-ModalPortal .orejime-ModalWrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  z-index: calc(10000 + 1);
  transform: translate3d(-50%, -50%, 0);
}
@media (--tablet) {
  .orejime-ModalPortal .orejime-ModalWrapper {
    width: 640px;
  }
}
.orejime-ModalPortal .orejime-Modal {
  font-size: 14px;
  background-color: #ffffff;
}
.orejime-ModalPortal .orejime-Modal-closeButton {
  position: absolute;
  top: 15px;
  right: 15px;
}
.orejime-ModalPortal .orejime-Modal-title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #00244c;
  margin-bottom: 15px;
  margin-top: 0;
}
.orejime-ModalPortal .orejime-Modal-description {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-size: 12px;
  color: #00244c;
}
.orejime-ModalPortal .orejime-Modal-privacyPolicyLink {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-size: 12px;
  color: #00244c;
  text-decoration: none;
  border-bottom: 1px dashed #b88210;
  transition: border 0.3s ease;
}
.orejime-ModalPortal .orejime-Modal-privacyPolicyLink:hover {
  border-bottom: 1px solid #b88210;
}
.orejime-ModalPortal .orejime-Modal-header {
  position: relative;
  padding: calc(15px * 2) calc(15px * 2) calc(15px - 5px);
  margin-bottom: 0;
  border-bottom: 1px solid #f9f2e8;
}
.orejime-ModalPortal .orejime-Modal-body {
  font-family: montserrat, arial, helvetica, sans-serif;
  color: #00244c;
  padding: 15px calc(15px * 2);
}
.orejime-ModalPortal .orejime-Modal-footer {
  text-align: center;
  padding: calc(15px + 5px) calc(15px * 2);
  border-top: 1px solid #f9f2e8;
}
.orejime-ModalPortal .orejime-AppToggles {
  text-align: center;
  margin-bottom: 15px;
}
.orejime-ModalPortal .orejime-AppToggles-button {
  margin-left: 8px;
  margin-right: 8px;
}
.orejime-ModalPortal .orejime-AppList {
  display: block;
  padding: 0;
  margin: 0;
}
.orejime-ModalPortal .orejime-AppList-item {
  display: block;
  position: relative;
  line-height: 1.5;
  vertical-align: middle;
  padding-left: 65px;
  margin-bottom: calc(15px * 2);
  min-height: 40px;
}
.orejime-ModalPortal .orejime-AppList-item:first-child {
  margin-top: 0;
}
.orejime-ModalPortal .orejime-AppItem {
  font-size: 14px;
}
.orejime-ModalPortal .orejime-AppItem label {
  color: #00244c;
}
.orejime-ModalPortal .orejime-AppItem-title {
  font-weight: 600;
  cursor: pointer;
}
.orejime-ModalPortal .orejime-AppItem-required, .orejime-ModalPortal .orejime-AppItem-optOut {
  color: #b88210;
  font-weight: 400;
  margin-left: 5px;
}
.orejime-ModalPortal .orejime-AppItem-fullDescription {
  font-size: 12px;
}
.orejime-ModalPortal .orejime-AppItem .checker {
  opacity: 0;
  visibility: hidden;
  width: 0;
  height: 0;
  margin: 0;
}

/**
 * ----------------------------------
 * form
 * ----------------------------------
 */
button:matches(.orejime-Notice-learnMoreButton, .orejime-Notice-declineButton, .orejime-Notice-saveButton) {
  color: #00244c;
  background-color: transparent;
  border: 2px solid #00244c;
  font-family: montserrat, arial, helvetica, sans-serif;
  margin-bottom: 15px;
}
button:matches(.orejime-Notice-learnMoreButton, .orejime-Notice-declineButton, .orejime-Notice-saveButton):hover {
  color: #ffffff;
  background-color: #b88210;
  border-color: #ffffff;
}
button:matches(.orejime-Modal-saveButton) {
  color: #00244c;
  background-color: transparent;
  border: 2px solid #00244c;
  font-family: montserrat, arial, helvetica, sans-serif;
}
button:matches(.orejime-Modal-saveButton):hover {
  color: #ffffff;
  background-color: #b88210;
  border-color: #ffffff;
}
button:matches(.orejime-Modal-closeButton) {
  background-color: transparent;
  border: none;
  padding: 0;
  line-height: 0;
}
button:matches(.orejime-Modal-closeButton) svg {
  stroke: #00244c;
}
button:matches(.orejime-AppToggles-disableAll, .orejime-AppToggles-enableAll) {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 600;
  position: relative;
  background-color: transparent;
  border: none;
  padding: 0;
  color: #00244c;
  color: #00244c;
  text-decoration: none;
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
}
button:matches(.orejime-AppToggles-disableAll, .orejime-AppToggles-enableAll):after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #b88210;
  transform: scaleX(1);
  transform-origin: center;
  transition: transform 0.3s ease;
}
button:matches(.orejime-AppToggles-disableAll, .orejime-AppToggles-enableAll):hover:after {
  transform: scaleX(0);
}
button:matches(.orejime-AppToggles-disableAll, .orejime-AppToggles-enableAll)[disabled] {
  opacity: 0.3;
}

.orejime-AppItem-switch {
  position: absolute;
  left: 0;
  display: inline-block;
  width: 50px;
  height: 25px;
}

.orejime-AppItem-slider {
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19);
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00244c;
  transition: 0.3s;
  width: 50px;
  display: inline-block;
  border-radius: 30px;
  border: 1px solid transparent;
}
.orejime-AppItem-slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 4px;
  bottom: 4px;
  background-color: #ffffff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

.orejime-AppItem-switchLabel {
  position: absolute;
  width: 50px;
  top: 34px;
  text-overflow: ellipsis;
  font-size: 0.8em;
  text-align: center;
}

.orejime-AppItem-switch--disabled .orejime-AppItem-slider {
  cursor: default;
  opacity: 0.5;
}
.orejime-AppItem-switch--disabled .orejime-AppItem-switchLabel {
  color: #00244c;
}

.orejime-AppItem-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 50px;
  height: 30px;
}
.orejime-AppItem-input:focus + .orejime-AppItem-label .orejime-AppItem-slider {
  border-color: #ffffff;
  outline-offset: 1px;
}
.orejime-AppItem-input:checked + .orejime-AppItem-label .orejime-AppItem-slider {
  background-color: #00244c;
}
.orejime-AppItem-input:checked + .orejime-AppItem-label .orejime-AppItem-slider:before {
  transform: translateX(24px);
}